import { TextField as MuiTextField } from '@mui/material';
import React, { FC } from 'react';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import styles from './textField.module.css';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { TextFieldProps } from '@mui/material/TextField/TextField';

interface Props {
  value?: string | number;
  type?: 'default' | 'edit';
  disabled?: boolean;
  placeholder?: string;
  label?: string;
  onChange?: OutlinedInputProps['onChange'];
}

export const TextField: FC<Props> = ({
  value,
  onChange,
  disabled,
  placeholder,
  label,
  type = 'default'
}) => {
  const props: TextFieldProps = {
    value,
    onChange,
    placeholder,
    label
  };

  if (type === 'edit')
    props.InputProps = { endAdornment: <EditOutlinedIcon /> };

  return (
    <MuiTextField
      className={disabled ? styles.textField__disabled : styles.textField}
      variant="outlined"
      fullWidth
      id="outlined-basic"
      onFocus={(e) => {
        if (disabled) {
          e.target.blur(); // Immediately remove focus by blurring the element
        }
      }}
      {...props}
    />
  );
};
