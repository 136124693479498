import { getAnyLocationWhereUserIsAdmin } from './methods/get/getAnyLocationWhereUserIsAdmin';
import { createOrganisation } from './methods/post/createOrganisation';
import { getLocation } from './methods/get/getLocation';
import { getAllOrganisationWhereUserIsAdmin } from './methods/get/getAllOrganisationWhereUserIsAdmin';
import { getAnyLocationIdFromOrganisation } from './methods/get/getAnyLocationIdFromOrganisation';
import { getAllLocationsWhereUserIsAdminWithingOrganisation } from './methods/get/getAllLocationsWhereUserIsAdminWithingOrganisation';
import { deleteLocation } from './methods/delete/deleteLocation';
import { createLocation } from './methods/post/createLocation';
import { getAdminsAndInvites } from './methods/get/getAdminList';
import { getUserProfileFromEmail } from './methods/get/getUserIdFromEmail';
import { getUserProfile } from './methods/get/getUserProfile';
import { AddAdmin } from './methods/post/addAdmin';
import { removeAdmin } from './methods/delete/removeAdmin';
import { getFoundItems } from './methods/get/getFoundItems';
import { getInquiries } from './methods/get/getInquiries';
import { getMatchmakingData } from './methods/get/getMatchmakingData';
import { getMatches } from './methods/get/getMatches';
import { updateMatch } from './methods/put/updateMatch';
import { updateFoundItem } from './methods/put/updateFoundItem';
import { updateInquiry } from './methods/put/updateInquiry';
import { getInquiry } from './methods/get/getInquiry';
import { getFoundItem } from './methods/get/getFoundItem';
import { deleteMatches } from './methods/delete/deteleteMatches';
import { getLocationData } from './methods/get/getLocationData';
import { updateLocation } from './methods/put/updateLocation';
import { archiveItems } from './methods/put/archiveItems';
import { upsertInquiryForm } from './methods/upsert/upsertInquiryForm';
import { getInquiryForm } from './methods/get/getInquiryForm';
import { sendFeedback } from './methods/post/sendFeedback';
import { getArchivedMatches } from './methods/get/getArchivedMatches';
import { unArchiveItems } from './methods/put/unArchiveItems';
import { deleteUserInvite } from './methods/delete/deleteUserInvite';
import { getEmailTemplate } from './methods/get/getEmailTemplate';
import { upsertInquiryEmailTemplate } from './methods/upsert/upsertInquiryEmailTemplate';
import { deleteScheduledEmail } from './methods/delete/deleteScheduledEmail';
import { updateUserProfile } from './methods/put/updateUserProfile';
import { getUsersAndInvites } from './methods/get/getUsersAndInvites';
import { getAllLocationsInOrganisation } from './methods/get/getAllLocationsInOrganisation';
import { deleteUserRolesInOrg } from './methods/delete/deleteUserRolesInOrg';

class Api {
  static get = class {
    static locationData = getLocationData;
    static anyLocationWhereUserIsAdmin = getAnyLocationWhereUserIsAdmin;
    static anyLocationIdFromOrganisation = getAnyLocationIdFromOrganisation;
    static allOrganisationWhereUserIsAdmin = getAllOrganisationWhereUserIsAdmin;
    static allLocationsWhereUserIsAdminWithingOrganisation =
      getAllLocationsWhereUserIsAdminWithingOrganisation;
    static allLocationsInOrganisation = getAllLocationsInOrganisation;
    static adminsAndInvites = getAdminsAndInvites;
    static usersAndInvites = getUsersAndInvites;
    static userProfileFromEmail = getUserProfileFromEmail;
    static userProfile = getUserProfile;
    static foundItems = getFoundItems;
    static foundItem = getFoundItem;
    static inquiries = getInquiries;
    static inquiry = getInquiry;
    static location = getLocation;
    static matchmakingData = getMatchmakingData;
    static matches = getMatches;
    static archivedMatches = getArchivedMatches;
    static inquiryForm = getInquiryForm;
    static emailTemplate = getEmailTemplate;
  };
  static post = class {
    static organisation = createOrganisation;
    static location = createLocation;
    static admin = AddAdmin;
    static feedback = sendFeedback;
  };
  static delete = class {
    static location = deleteLocation;
    static admin = removeAdmin;
    static userInvite = deleteUserInvite;
    static matches = deleteMatches;
    static scheduledEmail = deleteScheduledEmail;
    static userRolesInOrg = deleteUserRolesInOrg;
  };
  static put = class {
    static archiveItems = archiveItems;
    static unArchiveItems = unArchiveItems;
    static foundItem = updateFoundItem;
    static inquiry = updateInquiry;
    static match = updateMatch;
    static location = updateLocation;
    static userProfile = updateUserProfile;
  };
  static upsert = class {
    static upsertInquiryForm = upsertInquiryForm;
    static inquiryEmailTemplate = upsertInquiryEmailTemplate;
  };
}

export default Api;
