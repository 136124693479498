import React, { FC, useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import 'dayjs/locale/da';
import styles from './newFoundItemButton.module.css';
import {
  CookieName,
  getBooleanCookie,
  setCookie
} from '../../../../../utils/cookies/cookie';
import { useLocationData } from '../../../../../contexts/LocationDataContext/LocationDataContext';
import { ItemCategory } from '../../../../../types/supabase/collections';
import { SetState } from '../../../../../sharedPacakge/types/utilityTypes';
import { useSnackbar } from '../../../../../sharedPacakge/components/Snackbar/SnackbarContext';
import { scaleAndMinifyImage } from '../../../../../sharedPacakge/utils/imageScaleMinify';
import { FormModal } from '../../../../../sharedPacakge/components/Modal/FormModal/FormModal';
import { Switch } from '../../../../../sharedPacakge/components/Switch/Switch';
import ImageUploader from '../../../../../sharedPacakge/components/ImageUploader/ImageUploader';
import CategoryDropdown from '../../../../../sharedPacakge/components/CategoryDropdown.tsx/CategoryDropdown';
import {
  OptionalNewFoundItemData,
  OptionalNewFoundItemFields
} from './components/OptionalNewFoundItemFields/OptionalNewFoundItemFields';
import { usePostFoundItem } from './postFoundItem';

interface Props {
  setIsOpen: SetState<boolean>;
  uploadedImage: File;
}

const NewFoundItemModal: FC<Props> = ({ setIsOpen, uploadedImage }) => {
  const { showSnackbar } = useSnackbar();
  const { postFoundItem } = usePostFoundItem();
  const { organisation, location, itemCategories } = useLocationData();
  const [useImageDetection, setUseImageDetection] = useState<boolean>(
    getBooleanCookie(CookieName.EnabledFoundItemCreationImageDetection) ?? true
  );
  const [loadingImageDetection, setLoadingImageDetection] =
    useState<boolean>(false);
  const [imageFile, setImageFile] = useState<File>(uploadedImage);
  const [parentCategory, setParentCategory] = useState<ItemCategory | null>(
    null
  );
  const [category, setCategory] = useState<ItemCategory | null>(null);
  const [description, setDescription] = useState('');
  const [disableSubmitButton, setDisableSubmitButton] =
    useState<boolean>(false);
  const [isHighValueItem, setIsHighValueItem] = useState<boolean>(false);
  const [optionalFields, setOptionalFields] =
    useState<OptionalNewFoundItemData>({
      foundAtDateTime: new Date(),
      foundAtLocation: '',
      storageLocation: '',
      notes: ''
    });

  useEffect(() => {
    setCookie(
      CookieName.EnabledFoundItemCreationImageDetection,
      useImageDetection,
      Infinity
    );
  }, [useImageDetection]);

  useEffect(() => {
    if (!useImageDetection) {
      if (description === '' || !category) {
        setDisableSubmitButton(true);
      } else {
        setDisableSubmitButton(false);
      }
      return;
    }

    setDisableSubmitButton(false);
  }, [useImageDetection, description, category, imageFile]);

  if (!organisation || !location) return null;

  const onSubmit = async (e: any) => {
    e.preventDefault();
    const postFoundItemGenericData = {
      storage_location: optionalFields.storageLocation,
      notes: optionalFields.notes,
      found_at_location: optionalFields.foundAtLocation,
      found_at_date: optionalFields.foundAtDateTime.toISOString()
    };

    if (useImageDetection) {
      postFoundItem(imageFile, {
        ...postFoundItemGenericData,
        imageDetection: true
      });
    } else {
      if (!category)
        return showSnackbar('Kategori eller under kategori mangler', 'error');

      const postData = {
        ...postFoundItemGenericData,
        imageDetection: false,
        category_id: category.id,
        description: description,
        is_high_value_item: isHighValueItem
      };

      postFoundItem(imageFile, postData);
    }
    setIsOpen(false);
  };

  const onFileUpload = (file: File) => {
    setLoadingImageDetection(true);
    scaleAndMinifyImage(file, 800, 800, 0.5)
      .then((optimzedImage) => {
        setImageFile(optimzedImage);
        setLoadingImageDetection(false);
      })
      .catch(() => {
        setLoadingImageDetection(false);
      });
  };

  const onNameChange = (e: any) => {
    setDescription(e.target.value);
  };

  const parentCategories = itemCategories
    .filter((category) => category.parent_category_id === null)
    .sort((a, b) =>
      a.name_danish.toLowerCase().localeCompare(b.name_danish.toLowerCase())
    );

  return (
    <FormModal
      onFormSubmit={onSubmit}
      title="Tilføj fundet genstand"
      setIsOpen={setIsOpen}
      submitButtonText="Udfør"
      disabled={disableSubmitButton}
    >
      <div className={styles.container}>
        <Switch
          uniqueId={'useImageDetection-switch'}
          state={useImageDetection}
          label="Benyt billedegenkendelse"
          onChange={() => setUseImageDetection((prevState) => !prevState)}
        />
        <ImageUploader
          onFileUpload={onFileUpload}
          previewImage={imageFile}
          renderText={(image) => (
            <div style={{ padding: 7 }}>
              <img
                src={image}
                alt="Preview"
                style={{
                  width: '100%',
                  height: 'fit-content',
                  maxHeight: '200px',
                  objectFit: 'contain'
                }}
                width={600}
                height={200}
              />
            </div>
          )}
        />
        {!useImageDetection && (
          <>
            <CategoryDropdown
              label="Kategori"
              value={parentCategory}
              itemCategories={parentCategories}
              onChange={(e, newValue: ItemCategory | null) => {
                if (newValue) {
                  // mategerial UI will get warnings if we don't use exact object refference
                  const exactObjRef = parentCategories.find(
                    (p) => p.id === newValue.id
                  ) as ItemCategory;
                  setParentCategory(exactObjRef);
                  setCategory(null);
                } else setParentCategory(null);
              }}
            />
            {parentCategory && (
              <CategoryDropdown
                label="Under kategori"
                value={category}
                itemCategories={itemCategories
                  .filter(
                    (category) =>
                      category.parent_category_id === parentCategory.id
                  )
                  .sort((a, b) =>
                    a.name_danish
                      .toLowerCase()
                      .localeCompare(b.name_danish.toLowerCase())
                  )}
                onChange={(e, newValue: ItemCategory | null) => {
                  if (newValue) {
                    // mategerial UI will get warnings if we don't use exact object refference
                    const exactObjRef = itemCategories.find(
                      (p) => p.id === newValue.id
                    ) as ItemCategory;
                    setCategory(exactObjRef);
                  } else setCategory(null);

                  if (newValue) setIsHighValueItem(newValue.is_high_value);
                }}
              />
            )}
            <TextField
              fullWidth
              id="outlined-basic"
              label="Beskrivelse"
              variant="outlined"
              value={description}
              onChange={onNameChange}
              disabled={loadingImageDetection}
            />
            <Switch
              uniqueId={'isHighValueFoundItem-switch'}
              state={isHighValueItem}
              label="Genstand af værdi over 500kr."
              onChange={() => setIsHighValueItem((prevState) => !prevState)}
            />
          </>
        )}
        <OptionalNewFoundItemFields
          optionalFields={optionalFields}
          setOptionalFields={setOptionalFields}
        />
      </div>
    </FormModal>
  );
};

export default NewFoundItemModal;
