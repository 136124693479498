import React, { FC, useState, useRef, useEffect, ReactNode } from 'react';
import {
  TextField,
  List,
  ListItem,
  Paper,
  ClickAwayListener
} from '@mui/material';
import styles from './autoCompleteInput.module.css';
import ReactDOM from 'react-dom';
import useViewportSize from './useViewportSize';

interface Props {
  label?: string;
  placeholder?: string;
  icon?: ReactNode;
  options: string[];
  value: string;
  onChange: (newValue: string) => void;
  maxOptions?: number;
}

export const AutocompleteInput: FC<Props> = ({
  label,
  placeholder,
  icon,
  value,
  options,
  onChange,
  maxOptions = 20
}) => {
  const actualVisibleViewport = useViewportSize();
  const [filteredOptions, setFilteredOptions] = useState<string[]>(options);
  const [open, setOpen] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState<{
    top: number;
    left: number;
  } | null>(null);
  const [dropdownMaxHeight, setDropdownMaxHeight] = useState<number | string>(
    'auto'
  );
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current && actualVisibleViewport) {
      const [, viewportHeight] = actualVisibleViewport;
      const rect = containerRef.current.getBoundingClientRect();
      const availableHeight = Math.min(
        viewportHeight - rect.bottom - 16, // 16px margin from bottom of viewport
        300 // maximum height for dropdown
      );
      setDropdownMaxHeight(availableHeight);
      setDropdownPosition({
        top: rect.bottom + window.scrollY, // Adjust for page scroll
        left: rect.left + window.scrollX // Adjust for page scroll
      });
    }
  }, [actualVisibleViewport, open]);

  useEffect(() => {
    if (!open) return;

    if (value) {
      setFilteredOptions(
        options
          .filter((option) =>
            option.toLowerCase().includes(value.toLowerCase())
          )
          .slice(0, maxOptions)
      );
      setOpen(true);
    } else {
      setFilteredOptions(options.slice(0, maxOptions));
      // setOpen(false);
    }
  }, [value, options, open]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setOpen(!!value);
    onChange(value);
  };

  const handleOptionSelect = (option: string) => {
    onChange(option);
    setOpen(false);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const handleFocus = () => {
    setOpen(true);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      setOpen(false);
      // a callback could be made here
    }
  };

  const portal = document.getElementById('autocomplete-root');

  if (!portal) {
    console.warn("Modal can't open, there is no modal root in the html");
    return null;
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div style={{ position: 'relative' }} ref={containerRef}>
        <TextField
          className={styles.textField}
          label={label}
          placeholder={placeholder}
          variant="outlined"
          value={value}
          onChange={handleInputChange}
          onFocus={handleFocus}
          autoComplete="off"
          onKeyDown={handleKeyDown}
          InputProps={{
            endAdornment: icon
          }}
          fullWidth
        />
        {options.length > 0 &&
          open &&
          dropdownPosition &&
          ReactDOM.createPortal(
            <Paper
              style={{
                position: 'absolute',
                top: dropdownPosition.top,
                left: dropdownPosition.left,
                zIndex: 1300, // Higher than most MUI components
                maxHeight: dropdownMaxHeight,
                overflowY: 'auto',
                width: containerRef.current?.clientWidth ?? 'auto' // Match the width of the input field
              }}
            >
              <List className={styles.options__container}>
                {filteredOptions.length > 0 ? (
                  filteredOptions.map((option, index) => (
                    <button
                      key={index}
                      onClick={() => handleOptionSelect(option)}
                      className={styles.option}
                    >
                      {option}
                    </button>
                  ))
                ) : (
                  <ListItem component="div" style={{ fontStyle: 'italic' }}>
                    Ingen forslag
                  </ListItem>
                )}
              </List>
            </Paper>,
            portal
          )}
      </div>
    </ClickAwayListener>
  );
};
