import React, { useEffect, useState } from 'react';
import styles from './viewInquiryModal.module.css';
import {
  ArchivedInquiry,
  Inquiry
} from '../../../../../types/supabase/collections';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { formatDate } from '../../../../../utils/formatting/formatDate';
import { useLocationData } from '../../../../../contexts/LocationDataContext/LocationDataContext';
import Api from '../../../../../servieces/Api/Api';
import { getDateDaysFromNow } from '../../../../../utils/dateInXDays';
import {
  daysUntilDate,
  daysUntilDateText
} from '../../../../../utils/daysUntilDate';
import { unMatchItems } from '../../../../../servieces/matchmaking/unMatchItems';
import { Button } from '../../../../../sharedPacakge/components/Button/Button';
import { SetState } from '../../../../../sharedPacakge/types/utilityTypes';
import { useSnackbar } from '../../../../../sharedPacakge/components/Snackbar/SnackbarContext';
import { InquiryArchiveReason } from '../../../../../sharedPacakge/types';
import { ItemStatus } from '../../../../../sharedPacakge/types/ItemStatus';
import { Modal } from '../../../../../sharedPacakge/components/Modal/Modal';
import { translateItemStatus } from '../../../../../utils/translate/translateItemStatus';
import logger from '../../../../../sharedPacakge/logger';
import { LoadingSpinner } from '../../../../../sharedPacakge/components/LoadingSpinner/LoadingSpinner';

interface Props<T> {
  inquiry: T;
  setIsOpen: SetState<boolean>;
  callbacks?: {
    onArchiveCallback?: (id: number) => void;
    onUnArchiveCallback?: (id: number) => void;
    onUnmatchedCallback?: (id: number) => void;
  };
}
function ViewInquiryModal<T extends Inquiry | ArchivedInquiry>({
  inquiry,
  setIsOpen,
  callbacks
}: Props<T>) {
  const isArchivedItem = 'archive_reason' in inquiry;
  const { location, itemCategories } = useLocationData();
  const { showSnackbar } = useSnackbar();
  const [resolveReason, setResolveReason] = useState<InquiryArchiveReason | ''>(
    ''
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [matchId, setMatchId] = useState<number | null>(null);
  const [hasMatch, setHasmatch] = useState<boolean>(false);

  useEffect(() => {
    if (
      inquiry.status === ItemStatus.ReadyForPickup ||
      inquiry.status === ItemStatus.LeftBehind
    ) {
      setIsLoading(true);

      if (isArchivedItem) {
        Api.get
          .archivedMatches({
            customQuery: (query) =>
              query.eq('inquiry_id', inquiry.id).eq('match_confirmed', true)
          })
          .then((matches) => {
            if (matches.length !== 1) {
              logger.error(
                `ViewInquiryModal !matches || matches.length !== 1 -> ${JSON.stringify(matches)}`
              );
              return;
            }
            setMatchId(matches[0].found_item_id);
            setHasmatch(true);
            setIsLoading(false);
          });
      } else {
        Api.get
          .matches({
            customQuery: (query) =>
              query.eq('inquiry_id', inquiry.id).eq('match_confirmed', true)
          })
          .then((matches) => {
            if (matches.length !== 1) {
              logger.error(
                `ViewInquiryModal !matches || matches.length !== 1 -> ${JSON.stringify(matches)}`
              );
              return;
            }
            setMatchId(matches[0].found_item_id);
            setHasmatch(true);
            setIsLoading(false);
          });
      }
    }
  }, []);

  const onResolveClick = (e: any) => {
    e.preventDefault();
    if (!resolveReason) {
      showSnackbar('Mangler årsag', 'error');
      return;
    }

    setIsLoading(true);
    Api.put
      .archiveItems({
        ids: [inquiry.id],
        type: 'inquiries',
        archiveReason: resolveReason
      })
      .then(() => {
        callbacks?.onArchiveCallback && callbacks.onArchiveCallback(inquiry.id);
        setIsLoading(false);
        showSnackbar('Afviklet');
        setIsOpen(false);
      })
      .catch(() => showSnackbar('Der skete en fejl', 'error'));
  };

  const onReopenClick = (e: any) => {
    e.preventDefault();
    if (!isArchivedItem) return;

    setIsLoading(true);
    Api.put
      .unArchiveItems({
        ids: [inquiry.id],
        type: 'inquiries',
        newExpiryDate: getDateDaysFromNow(10)
      })
      .then(() => {
        callbacks?.onArchiveCallback && callbacks.onArchiveCallback(inquiry.id);
        setIsLoading(false);
        showSnackbar('genåbnet');
        setIsOpen(false);
      })
      .catch(() => showSnackbar('Der skete en fejl', 'error'));
  };

  if (!location) return null;
  const onUnMatchClick = async (e: any) => {
    e.preventDefault();
    if (isArchivedItem || !matchId) return;
    setIsLoading(true);
    unMatchItems({
      inquiryId: inquiry.id,
      foundItemId: matchId,
      location
    })
      .then(() => {
        showSnackbar('Efterlysning er Unmatched!');
        callbacks?.onUnmatchedCallback &&
          callbacks.onUnmatchedCallback(inquiry.id);
        setIsOpen(false);
      })
      .catch(() => showSnackbar('Der skete en fejl', 'error'))
      .finally(() => setIsLoading(false));
  };

  return (
    <Modal
      className={styles.modal}
      title={`Efterlyst hos ${location.name}`}
      setIsOpen={setIsOpen}
    >
      <div className={styles.sectionContainer}>
        <p className={styles.sectionTitle}>Billede</p>
        {inquiry?.image ? (
          <img
            className={styles.image}
            src={inquiry.image}
            alt="bilede"
            width={300}
            height={300}
          />
        ) : (
          <p style={{ textAlign: 'center' }}>(har ikke vedhæftet billede)</p>
        )}
      </div>

      <div className={styles.sectionContainer}>
        <p className={styles.sectionTitle}>
          Mistede genstand {isArchivedItem ? <small> (Arkiveret)</small> : ''}
        </p>
        <p className={styles.idText}>
          <strong style={{ fontWeight: 'bold' }}>Id:</strong> {inquiry.id}
        </p>
        <div className={styles.section}>
          <p className={styles.text}>
            <strong style={{ fontWeight: 'bold' }}>Kategori:</strong>{' '}
            {inquiry.category.name_danish}
          </p>
          <p className={styles.text}>
            <strong style={{ fontWeight: 'bold' }}>Beskrivelse:</strong>{' '}
            {inquiry.description}
          </p>
          <p className={styles.text}>
            <strong style={{ fontWeight: 'bold' }}>Mistet Omkring:</strong>{' '}
            {formatDate(inquiry.lost_at_date)}
          </p>
        </div>
      </div>

      {!isArchivedItem && (
        <div className={styles.sectionContainer}>
          <div className={styles.section}>
            <p className={styles.text}>
              <strong style={{ fontWeight: 'bold' }}>Status:</strong>{' '}
              {translateItemStatus(inquiry.status)}
            </p>
            <p className={styles.text}>
              <strong style={{ fontWeight: 'bold' }}>Udløber om:</strong>{' '}
              {daysUntilDateText(inquiry.expiry_date)}
            </p>
            <p className={styles.text}>
              <strong style={{ fontWeight: 'bold' }}>Match:</strong>{' '}
              {matchId ? `genstands-id: ${matchId}` : 'ingen match'}
            </p>
          </div>
        </div>
      )}

      <div className={styles.sectionContainer}>
        <div className={styles.section}>
          <p className={styles.text}>
            <strong style={{ fontWeight: 'bold' }}>Mistet ved:</strong>{' '}
            {inquiry.lost_at_location === ''
              ? 'ikke specificeret'
              : inquiry.lost_at_location}
          </p>{' '}
          {isArchivedItem && (
            <p className={styles.text}>
              <strong style={{ fontWeight: 'bold' }}>Match:</strong>{' '}
              {matchId ? `efterlysnings-id: ${matchId}` : 'ingen match'}
            </p>
          )}
        </div>
      </div>

      <div className={styles.sectionContainer}>
        <p className={styles.sectionTitle}>Efterlyser</p>
        <div className={styles.section}>
          <p className={styles.text}>
            <strong style={{ fontWeight: 'bold' }}>navn:</strong>{' '}
            {inquiry.reporter_name}
          </p>
          <p className={styles.text}>
            <strong style={{ fontWeight: 'bold' }}>email:</strong>{' '}
            {inquiry.reporter_email}
          </p>
          <p className={styles.text}>
            <strong style={{ fontWeight: 'bold' }}>Telefon:</strong>{' '}
            {inquiry.reporter_phone}
          </p>
        </div>
      </div>

      {!isArchivedItem && !hasMatch && !isLoading && (
        <>
          <p className={styles.sectionTitle}>Afvikl</p>
          <div className={styles.sectionContainer}>
            <div className={styles.section}>
              <FormControl fullWidth disabled={isLoading}>
                <InputLabel id="demo-simple-select-label">Årsag</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={resolveReason}
                  label="Årsag"
                  onChange={(e: any) => {
                    setResolveReason(e.target.value as InquiryArchiveReason);
                  }}
                >
                  <MenuItem
                    value={
                      '(manually archived) Physical inquiry & immediate pickup'
                    }
                  >
                    Ejer henvendte sig fysisk
                  </MenuItem>
                  <MenuItem value={'(manually archived) Donated'}>
                    Doneret
                  </MenuItem>
                  <MenuItem value={'(manually archived) Handed to police'}>
                    Afleveret til Politiet
                  </MenuItem>
                  <MenuItem value={'(manually archived) Kept'}>
                    Beholdt selv
                  </MenuItem>
                </Select>
              </FormControl>
              <Button onClick={onResolveClick} disabled={isLoading}>
                Færdig
              </Button>
            </div>
          </div>
        </>
      )}
      {!isArchivedItem && hasMatch && !isLoading && (
        <>
          <p className={styles.idText}>
            Var efterlysningen matched med den genstand forkerte?
          </p>
          <Button
            onClick={onUnMatchClick}
            disabled={isLoading}
            buttonType="secondary"
            style={{ margin: 'auto' }}
          >
            Unmatch
          </Button>
        </>
      )}
      {isArchivedItem && !isLoading && (
        <>
          <p className={styles.idText}>
            <strong style={{ fontWeight: 'bold' }}>Afviklet:</strong>{' '}
            {formatDate(inquiry.archived_date)}
          </p>
          <p className={styles.idText}>Vil du åbne sagen op igen i 10 dage?</p>
          <Button
            onClick={onReopenClick}
            buttonType="secondary"
            style={{ margin: 'auto' }}
          >
            Genaktivér
          </Button>
        </>
      )}
      {isLoading && <LoadingSpinner />}
    </Modal>
  );
}

export default ViewInquiryModal;
