import { Modal } from '../../../../../../sharedPacakge/components/Modal/Modal';
import { Text } from '../../../../../../components/Text/Text';
import { FC, useState } from 'react';
import { TextField } from '../../../../../../components/TextField/TextField';
import { UserOrInvite } from '../../../../../../servieces/Api/methods/get/getUsersAndInvites';
import { Gap } from '../../../../../../components/Gap/Gap';
import CircleImage from '../../../../../../components/OrganisationLogo/OrganisationLogo';
import styles from './addUserModal.module.css';
import { useLocationData } from '../../../../../../contexts/LocationDataContext/LocationDataContext';
import { Button } from '../../../../../../sharedPacakge/components/Button/Button';
import logger from '../../../../../../sharedPacakge/logger';
import { useSnackbar } from '../../../../../../sharedPacakge/components/Snackbar/SnackbarContext';
import { SetState } from '../../../../../../sharedPacakge/types/utilityTypes';
import { sendAdminInviteEmail } from '../../sendAdminInviteEmail';
import Api from '../../../../../../servieces/Api/Api';
import { Role } from '../../../../../../types/supabase/collections';
import { getDateDaysFromNow } from '../../../../../../utils/dateInXDays';
import { RoleDropdown } from '../RoleDropdown/RoleDropdown';
import { supabase } from '../../../../../../servieces/supabase/supabaseClient';
import { assertNoSupabaseError } from '../../../../../../servieces/supabase/supabaseErrorHandling';

interface Props {
  onClose: () => void;
  currentUsers: UserOrInvite[];
  setUsersAndInvites: SetState<UserOrInvite[]>;
}
export const AddUserModal: FC<Props> = ({
  onClose,
  currentUsers,
  setUsersAndInvites
}) => {
  const { showSnackbar } = useSnackbar();
  const { organisation, location } = useLocationData();
  const { roleAccess } = useLocationData();

  const [email, setEmail] = useState<string>('');
  const [selectedRole, setSelectedRole] = useState<Role>(Role.GeneralUser);

  if (!organisation) return null;
  const onCancelClick = (e: any) => {
    e.preventDefault();
    onClose();
  };

  const onSubmitClick = async (e: any) => {
    e.preventDefault();
    if (!email.includes('@')) {
      showSnackbar('Invalid email', 'error');
      return;
    }
    if (currentUsers.find((userOrInvite) => userOrInvite.email === email)) {
      showSnackbar('Bruger eksisterer allerede');
      return;
    }

    try {
      const userProfile = await Api.get.userProfileFromEmail(email);
      if (!userProfile) {
        const newAdmin = {
          email: email.toLowerCase(),
          role: selectedRole,
          expiry_date: getDateDaysFromNow(30).toISOString(),
          ...(selectedRole === Role.OrganisationAdmin
            ? {}
            : { location_id: location.id }),
          organisation_id: organisation.id
        };
        const { data: newAdminData, error: newAdminError } = await supabase
          .from('user_invites')
          .insert(newAdmin)
          .select('id, expiry_date')
          .single();

        assertNoSupabaseError(
          newAdminData,
          newAdminError,
          'getAllAdmins userInvitesError:',
          newAdmin,
          true
        );

        await sendAdminInviteEmail(email, location, organisation, false);

        setUsersAndInvites((prevState) => [
          ...prevState,
          {
            isUserInvite: true,
            id: newAdminData.id,
            email: email,
            fullName: email.split('@')[0],
            expiryDate: new Date(newAdminData.expiry_date),
            role: selectedRole,
            locationIds: [location!.id]
          }
        ]);
        showSnackbar('administratorer blev opdateret');
        onClose();
        return;
      }

      await Api.post.admin({
        userIdToAdd: userProfile.id,
        roleType: selectedRole,
        locationId: location.id,
        organisationId: organisation.id
      });
      setUsersAndInvites((prevState) => [
        ...prevState,
        {
          isUserInvite: false,
          id: userProfile.id,
          userId: userProfile.id,
          email: userProfile.email,
          fullName: userProfile.fullName,
          lastActiveAt: userProfile.lastActiveAt,
          role: selectedRole,
          locationIds:
            selectedRole === Role.OrganisationAdmin ? [] : [location.id]
        }
      ]);
      showSnackbar('administratorer blev opdateret');

      await sendAdminInviteEmail(email, location, organisation, true);

      showSnackbar('administratorer blev opdateret');
      onClose();
    } catch (error) {
      logger.error('UserTab error adding admin');
      showSnackbar('Der skete en fejl', 'error');
    }
  };

  return (
    <Modal title="Rediger bruger" onClose={onClose}>
      <div className={styles.image__container}>
        <CircleImage altText={email} size="large" />
      </div>

      <Gap size={24} />
      <Text type="body-strong">Indtast brugerens email</Text>
      <Gap size={16} />

      <TextField
        label="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />

      <Gap size={16} />
      <Text type="body-strong">Vælg rolletype</Text>
      <Gap size={16} />

      <RoleDropdown
        value={selectedRole}
        onChange={(_, newValue) => setSelectedRole(newValue)}
        options={roleAccess?.manageUsers || []}
      />

      <Gap size={24} />
      <div style={{ display: 'flex', gap: 16 }}>
        <Button buttonType="secondary" fullWidth onClick={onCancelClick}>
          Annuler
        </Button>
        <Button fullWidth onClick={onSubmitClick} disabled={email === ''}>
          Tilføj
        </Button>
      </div>
      <Gap size={24} />
    </Modal>
  );
};
