import styles from '../../actionsTab.module.css';
import InventoryRoundedIcon from '@mui/icons-material/InventoryRounded';

import React, { useEffect, useState } from 'react';
import { TabLayout } from '../../../../components/TabLayout/TabLayout';
import {
  ArchivedFoundItem,
  FoundItem,
  Inquiry,
  MatchRecord
} from '../../../../../../types/supabase/collections';
import { useLocationData } from '../../../../../../contexts/LocationDataContext/LocationDataContext';
import Api from '../../../../../../servieces/Api/Api';
import ViewInquiryModal from '../../../InquiriesTab/ViewInquiryModal/ViewInquiryModal';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { useEventListener } from '../../../../../../contexts/EventsProvider.ts/EventsProvider';
import { ItemStatus } from '../../../../../../sharedPacakge/types/ItemStatus';
import { useSnackbar } from '../../../../../../sharedPacakge/components/Snackbar/SnackbarContext';
import { LoadingSpinner } from '../../../../../../sharedPacakge/components/LoadingSpinner/LoadingSpinner';
import FoundItemsTable from '../../../../components/FoundItemsTable/FoundItemTable';
import { Button } from '../../../../../../sharedPacakge/components/Button/Button';
import logger from '../../../../../../sharedPacakge/logger';
import foundItemTable from '../../../../components/FoundItemsTable/FoundItemTable';

export const AwaitingPickupTable = () => {
  const { location } = useLocationData();
  const { showSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [awaitingPickup, setAwaitingPickup] = useState<FoundItem[]>([]);
  const [viewingInquiry, setViewingInquiry] = useState<Inquiry>();
  const [viewingInquiryModalOpen, setViewingInquiryModalOpen] = useState(false);
  const [matchRecords, setMatchRecords] = useState<MatchRecord[]>([]);

  const getMatches = async (foundItems: FoundItem[]) => {
    const response = await Api.get.matches({
      customQuery: (query) =>
        query
          .in(
            'found_item_id',
            foundItems.map((i) => i.id)
          )
          .eq('match_confirmed', true)
    });
    setMatchRecords(response);
  };
  const fetchFoundItems = () => {
    if (!location) return;
    setIsLoading(true);
    Api.get
      .foundItems({
        location_id: location.id,
        customQuery: (query) => query.eq('status', ItemStatus.ReadyForPickup)
      })
      .then((awaitingPickupResponse) => {
        if (awaitingPickupResponse) {
          setAwaitingPickup(awaitingPickupResponse);
          getMatches(awaitingPickupResponse);
        }
        setIsLoading(false);
      });
  };

  useEventListener('foundItemUploaded', fetchFoundItems);
  useEffect(() => {
    fetchFoundItems();
  }, [location]);

  if (!location) return null;

  if (isLoading)
    return (
      <TabLayout>
        <div>
          <LoadingSpinner />
        </div>
      </TabLayout>
    );

  const getMatch = (foundItem: FoundItem) =>
    matchRecords.find((m) => m.found_item_id === foundItem.id);

  const onPickedUpClick = async (e: any, foundItem: FoundItem) => {
    e.preventDefault();

    await Api.put.archiveItems({
      ids: [foundItem.id],
      type: 'foundItems',
      archiveReason: 'Picked up by owner'
    });
    setAwaitingPickup(
      (prevState) => prevState.filter((item) => item.id !== foundItem.id) // removes item in state
    );
    showSnackbar('genstand er noteret som afhentet');
    // her bør vi sende en trustpilot mail
  };

  const onShowInquiryClick = async (e: any, foundItem: FoundItem) => {
    e.preventDefault();
    try {
      const matchRecord = getMatch(foundItem);
      if (!matchRecord) return;

      const inquiry = await Api.get.inquiry(matchRecord.inquiry_id);
      setViewingInquiry(inquiry);
      setViewingInquiryModalOpen(true);
    } catch (error) {
      logger.error(`onShowInquiryClick error ${JSON.stringify(error)}`);
      showSnackbar('Der skete en fejl');
    }
  };

  const removeFoundItemLocally = (id: number) => {
    setAwaitingPickup((prevState) =>
      prevState.filter((item) => item.id !== id)
    );
  };

  const updateFoundItem = (
    id: number,
    newValue: FoundItem | ArchivedFoundItem
  ) => {
    setAwaitingPickup((prevState) =>
      prevState.map((item) => (item.id === id ? newValue : item))
    );
  };

  return (
    <>
      <FoundItemsTable
        isLoading={isLoading}
        foundItems={awaitingPickup}
        updateFoundItem={updateFoundItem}
        callbacks={{
          onUnmatchedCallback: removeFoundItemLocally,
          onArchiveCallback: removeFoundItemLocally
        }}
        options={{
          columns: ['image', 'category', 'description'],
          renderNoFoundItems: () => (
            <span className={styles.noFoundItems__text}>
              <InventoryRoundedIcon />
              <p>Du er helt ajour.</p>
            </span>
          ),
          renderActionsColumn: (row) => (
            <div className={styles.tableActions__container}>
              {getMatch(row) ? (
                <Button
                  buttonType="secondary"
                  onClick={(e) => onShowInquiryClick(e, row)}
                >
                  Se efterlysning
                </Button>
              ) : (
                'manuelt sat til afhentning'
              )}

              <Button onClick={(e) => onPickedUpClick(e, row)}>
                <DoneAllIcon />
                Afhentet
              </Button>
            </div>
          )
        }}
      />
      {viewingInquiryModalOpen && viewingInquiry && (
        <ViewInquiryModal
          callbacks={{ onUnmatchedCallback: () => window.location.reload() }}
          inquiry={viewingInquiry}
          setIsOpen={setViewingInquiryModalOpen}
        />
      )}
    </>
  );
};
