import React, { FC, useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import 'dayjs/locale/da';
import styles from './newFoundItemButton.module.css';
import { useLocationData } from '../../../../../contexts/LocationDataContext/LocationDataContext';
import { ItemCategory } from '../../../../../types/supabase/collections';
import { SetState } from '../../../../../sharedPacakge/types/utilityTypes';
import { useSnackbar } from '../../../../../sharedPacakge/components/Snackbar/SnackbarContext';
import { FormModal } from '../../../../../sharedPacakge/components/Modal/FormModal/FormModal';
import CategoryDropdown from '../../../../../sharedPacakge/components/CategoryDropdown.tsx/CategoryDropdown';
import {
  OptionalNewFoundItemData,
  OptionalNewFoundItemFields
} from './components/OptionalNewFoundItemFields/OptionalNewFoundItemFields';
import { usePostFoundItem } from './postFoundItem';
import DriversLiscenseImage from '../../../../../assets/images/DriversLiscense.jpg';
import HealthInsuranceCardImage from '../../../../../assets/images/HealthInsuranceCard.jpg';
import TravelCardImage from '../../../../../assets/images/TravelCard.jpg';
import PaymentCardImage from '../../../../../assets/images/PaymentCard.jpg';
import OtherTypeOfCardImage from '../../../../../assets/images/OtherTypeOfCard.jpg';

interface Props {
  setIsOpen: SetState<boolean>;
}

const CARD_CATEGORY_ID = 2;
const OTHER_TYPE_OF_CARD_CATEGORY_ID = 204;
const DRIVERS_LICENSE_CATEGORY_ID = 200;

export const categoryToImage = (categoryId: number): string => {
  switch (categoryId) {
    case DRIVERS_LICENSE_CATEGORY_ID:
      return DriversLiscenseImage;
    case 201:
      return PaymentCardImage;
    case 202:
      return TravelCardImage;
    case 203:
      return HealthInsuranceCardImage;
    default:
      return OtherTypeOfCardImage;
  }
};

const NewFoundCardModal: FC<Props> = ({ setIsOpen }) => {
  const { showSnackbar } = useSnackbar();
  const { postFoundItem } = usePostFoundItem();
  const { organisation, location, itemCategories } = useLocationData();
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [previewImage, setPreviewImage] = useState<string>('');
  const [category, setCategory] = useState<ItemCategory | null>(null);
  const [description, setDescription] = useState('');
  const [disableSubmitButton, setDisableSubmitButton] =
    useState<boolean>(false);
  const [isHighValueItem, setIsHighValueItem] = useState<boolean>(false);
  const [optionalFields, setOptionalFields] =
    useState<OptionalNewFoundItemData>({
      foundAtDateTime: new Date(),
      foundAtLocation: '',
      storageLocation: '',
      notes: ''
    });

  const categoryOptions = itemCategories
    .filter((category) => category.parent_category_id === CARD_CATEGORY_ID)
    .sort((a, b) =>
      a.name_danish.toLowerCase().localeCompare(b.name_danish.toLowerCase())
    );

  const createFileFromImage = async (imageSrc: string, filename: string) => {
    const response = await fetch(imageSrc);
    const blob = await response.blob();
    return new File([blob], filename, { type: blob.type });
  };

  const updateCategory = async (newValue: ItemCategory) => {
    setCategory(newValue);
    if (newValue) setIsHighValueItem(newValue.is_high_value);
    const newImage = categoryToImage(newValue.id);
    setPreviewImage(newImage);
    const file = await createFileFromImage(newImage, 'CardImage.jpg');
    setImageFile(file);
  };

  useEffect(() => {
    const driversLicenseCategory = categoryOptions.find(
      (c) => c.id === DRIVERS_LICENSE_CATEGORY_ID
    );

    driversLicenseCategory && updateCategory(driversLicenseCategory);
  }, []);

  if (!organisation || !location || !itemCategories) return null;

  const onSubmit = async (e: any) => {
    e.preventDefault();
    if (!imageFile) return;
    if (!category) return showSnackbar('Kort type mangler', 'error');
    if (!description || description === '')
      return showSnackbar('Beskrivelse mangler', 'error');

    const fullDescription =
      category?.id === OTHER_TYPE_OF_CARD_CATEGORY_ID
        ? description
        : `Navn: ${description}`;

    const postData = {
      storage_location: optionalFields.storageLocation,
      notes: optionalFields.notes,
      found_at_location: optionalFields.foundAtLocation,
      found_at_date: optionalFields.foundAtDateTime.toISOString(),
      category_id: category.id,
      description: fullDescription,
      is_high_value_item: isHighValueItem,
      imageDetection: false
    };

    postFoundItem(imageFile, postData);
    setIsOpen(false);
  };

  return (
    <FormModal
      onFormSubmit={onSubmit}
      title="Tilføj fundet genstand"
      setIsOpen={setIsOpen}
      submitButtonText="Udfør"
      disabled={disableSubmitButton}
    >
      <div className={styles.container}>
        <>
          <div style={{ padding: 7 }}>
            <img
              src={previewImage}
              alt="Preview"
              style={{
                width: '100%',
                height: 'fit-content',
                maxHeight: '200px',
                objectFit: 'contain'
              }}
              width={600}
              height={200}
            />
          </div>
          <CategoryDropdown
            label="Kort type"
            value={category}
            itemCategories={categoryOptions}
            onChange={(e, newValue: ItemCategory | null) =>
              newValue && updateCategory(newValue)
            }
          />

          <TextField
            fullWidth
            id="outlined-basic"
            label={
              category?.id === OTHER_TYPE_OF_CARD_CATEGORY_ID
                ? 'Beskrivelse'
                : 'Ejers fulde navn'
            }
            variant="outlined"
            value={description}
            onChange={(e: any) => setDescription(e.target.value)}
          />
        </>
        <OptionalNewFoundItemFields
          optionalFields={optionalFields}
          setOptionalFields={setOptionalFields}
        />
      </div>
    </FormModal>
  );
};

export default NewFoundCardModal;
