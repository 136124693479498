import PendingActionsIcon from '@mui/icons-material/PendingActions';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import ThreePOutlinedIcon from '@mui/icons-material/ThreePOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import BrushIcon from '@mui/icons-material/Brush';
import ModeCommentIcon from '@mui/icons-material/ModeComment';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import React from 'react';
import Page from '../../../Page';
import { Role } from '../../../../types/supabase/collections';
import { encodeIntId } from '../../../../sharedPacakge/utils/IntIdEncryption';

export const tabsConfiguration = (locationId: number) => [
  {
    name: 'Handlinger',
    description: 'Tag handling ét sted',
    icon: <PendingActionsIcon />,
    route: Page.l.actions(locationId),
    urlPattern: `/l/${encodeIntId(locationId)}/actions`,
    subTabs: [
      {
        name: 'Matchmaking',
        description: 'Matchmaking',
        urlPattern: 'actions/matchmaking'
      }
    ]
  },
  {
    name: 'Fundne genstande',
    description: 'Alle fundne genstande',
    icon: <CategoryOutlinedIcon />,
    route: Page.l.foundItems(locationId),
    urlPattern: `/l/${encodeIntId(locationId)}/found-items`
  },
  {
    name: 'Efterlysninger',
    description: 'Alle efterlysninger',
    icon: <ThreePOutlinedIcon />,
    route: Page.l.inquiries(locationId),
    urlPattern: `/l/${encodeIntId(locationId)}/inquiries`,
    dividerAfter: true
  },
  {
    name: 'Tilpasning',
    description: 'Tilpasning',
    icon: <SettingsOutlinedIcon />,
    route: Page.l.customization(locationId),
    urlPattern: `/l/${encodeIntId(locationId)}/customization`
  },
  {
    name: 'Efterlysnings formular',
    visibleForRoles: [Role.OrganisationAdmin],
    description: 'Tilpas din formular',
    icon: <BrushIcon />,
    route: Page.l.editInquiryForm(locationId),
    urlPattern: `/l/${encodeIntId(locationId)}/edit-inquiry-form`
  },
  {
    name: 'Emails (Beta)',
    visibleForRoles: [Role.OrganisationAdmin],
    description: 'Tilpas emails',
    icon: <MailOutlineIcon />,
    route: Page.l.emailTemplates(locationId),
    urlPattern: `/l/${encodeIntId(locationId)}/email-templates`
  },
  {
    name: 'Brugere',
    description: 'Håndter brugere',
    icon: <PeopleOutlinedIcon />,
    route: Page.l.users(locationId),
    urlPattern: `/l/${encodeIntId(locationId)}/users`,
    dividerAfter: true
  },
  {
    name: 'Hjælp & feedback',
    description: 'Feedback, forslag & fejl',
    icon: <ModeCommentIcon />,
    route: Page.l.feedback(locationId),
    urlPattern: `/l/${encodeIntId(locationId)}/feedback`
  }
];
