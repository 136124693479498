import { AutocompleteInput } from '../AutoCompleteInput/AutoCompleteInput';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import React, { FC } from 'react';
import useFilters from '../../pages/LocationPage/hooks/useFilters';

interface Props {
  options?: string[];
  placeholder?: string;
}
export const SearchBar: FC<Props> = ({ options, placeholder }) => {
  const [filters, udpateFilter] = useFilters();

  return (
    <div style={{ width: 230 }}>
      <AutocompleteInput
        label="Søg"
        placeholder={placeholder}
        icon={<SearchOutlinedIcon sx={{ color: '#C4C4C4 !important' }} />}
        value={filters.query || ''}
        onChange={(newValue) => udpateFilter({ query: newValue })}
        maxOptions={7}
        options={options ?? []}
      />
    </div>
  );
};
