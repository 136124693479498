import React, { FC, useEffect, useState } from 'react';
import styles from './FoundItemsTable.module.css';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { TabLayout } from '../../components/TabLayout/TabLayout';
import FoundItemsTable from '../../components/FoundItemsTable/FoundItemTable';
import { useLocationData } from '../../../../contexts/LocationDataContext/LocationDataContext';
import Api from '../../../../servieces/Api/Api';
import {
  ArchivedFoundItem,
  FoundItem
} from '../../../../types/supabase/collections';
import useFilters from '../../hooks/useFilters';
import { useEventListener } from '../../../../contexts/EventsProvider.ts/EventsProvider';
import { Switch } from '../../../../sharedPacakge/components/Switch/Switch';
import { AutocompleteInput } from '../../../../components/AutoCompleteInput/AutoCompleteInput';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { FoundItemArchiveReason } from '../../../../sharedPacakge/types';
import { archiveItems } from '../../../../servieces/Api/methods/put/archiveItems';
import { Button } from '../../../../sharedPacakge/components/Button/Button';
import { ItemStatus } from '../../../../sharedPacakge/types/ItemStatus';
import { SearchBar } from '../../../../components/SearchBar/SearchBar';

const FoundItemsTab: FC = () => {
  const { location } = useLocationData();
  const [filters, udpateFilter] = useFilters();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [foundItems, setFoundItems] = useState<
    FoundItem[] | ArchivedFoundItem[]
  >([]);
  const [selectedRows, setSelectedRows] = useState<FoundItem[]>([]);

  const onArchiveItemsClick = async (
    e: any,
    reason: FoundItemArchiveReason
  ) => {
    e.preventDefault();

    await archiveItems({
      ids: selectedRows.map((row) => row.id),
      type: 'foundItems',
      archiveReason: reason
    });

    setFoundItems((prevState) =>
      prevState.filter(
        (item) =>
          !selectedRows.some((selectedRow) => selectedRow.id === item.id)
      )
    );
    setSelectedRows([]);
  };

  const removeFoundItemLocally = (id: number) => {
    setFoundItems((prevState) => prevState.filter((item) => item.id !== id));
  };

  const fetchFoundItems = () => {
    if (!location) return;
    Api.get
      .foundItems({
        location_id: location.id,
        archived: filters.isArchived
      })
      .then(
        (foundItemsResponse) =>
          foundItemsResponse && setFoundItems(foundItemsResponse)
      );
    setIsLoading(false);
  };

  useEventListener('foundItemUploaded', fetchFoundItems);
  useEffect(() => {
    fetchFoundItems();
  }, [location, filters.isArchived]);

  const renderNoFoundItems = () => (
    <>
      <h2 className={styles.noItems__text}>
        {filters.isArchived
          ? 'Ingen afviklet genstande endnu'
          : 'Ingen fundne genstande endnu'}
      </h2>
      <p
        style={{
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '5px'
        }}
      >
        Opret fundne genstande
        <PostAddIcon />
      </p>
    </>
  );

  const updateFoundItem = (
    id: number,
    newValue: FoundItem | ArchivedFoundItem
  ) => {
    setFoundItems((prevState) =>
      prevState.map((item) => (item.id === id ? newValue : item))
    );
  };

  const queryFilter = (item: FoundItem) =>
    filters.query
      ? item.category.name_danish
          .toLowerCase()
          .includes(filters.query.toLowerCase()) ||
        item.description.toLowerCase().includes(filters.query.toLowerCase())
      : true;

  const filteredFoundItems = filters.query
    ? foundItems.filter((item) => queryFilter(item))
    : foundItems;

  const uniqueQueryFilterOptions = Array.from(
    new Set([
      ...filteredFoundItems.map((item) => item.description),
      ...filteredFoundItems.map((item) => item.category.name_danish)
    ])
  );

  return (
    <TabLayout>
      <div className={styles.container}>
        <div className={styles.filters__container}>
          <div className={styles.searchBar__container}>
            <SearchBar
              placeholder="Kategori eller beskrivelse"
              options={uniqueQueryFilterOptions}
            />
          </div>
          <div>
            <Switch
              uniqueId="foundItem-archive-switch"
              state={!!filters.isArchived}
              label="Vis afviklede"
              onChange={() => udpateFilter({ isArchived: !filters.isArchived })}
            />
          </div>
        </div>
        {selectedRows.length > 0 && (
          <div style={{ display: 'flex', gap: 15 }}>
            <Button onClick={(e) => onArchiveItemsClick(e, 'Handed to police')}>
              Afleveret til Politi
            </Button>
            <Button onClick={(e) => onArchiveItemsClick(e, 'Donated')}>
              Doneret
            </Button>
            <Button
              onClick={(e) => onArchiveItemsClick(e, 'Kept')}
              buttonType="secondary"
            >
              Beholdt selv
            </Button>
          </div>
        )}
        {filters.isArchived ? (
          <FoundItemsTable<ArchivedFoundItem>
            isLoading={isLoading}
            foundItems={filteredFoundItems as ArchivedFoundItem[]}
            updateFoundItem={updateFoundItem}
            callbacks={{
              onArchiveCallback: removeFoundItemLocally,
              onUnArchiveCallback: removeFoundItemLocally
            }}
            options={{
              renderNoFoundItems: renderNoFoundItems,
              columns: [
                'image',
                'category',
                'description',
                'found_at_date',
                'id',
                'archive_reason'
              ],
              customColumns: [
                {
                  id: 'expiryReason',
                  label: 'Årsag',
                  render: (row) =>
                    row?.status === ItemStatus.LeftBehind
                      ? 'blev aldrig afhentet'
                      : 'blev aldrig matchet'
                }
              ]
            }}
          />
        ) : (
          <FoundItemsTable<FoundItem>
            isLoading={isLoading}
            foundItems={filteredFoundItems}
            updateFoundItem={updateFoundItem}
            callbacks={{
              onArchiveCallback: removeFoundItemLocally,
              onUnArchiveCallback: removeFoundItemLocally
            }}
            options={{
              renderNoFoundItems: renderNoFoundItems,
              columns: [
                'image',
                'category',
                'description',
                'found_at_date',
                'id',
                'status'
              ],
              multiSelect: {
                enabled: true,
                onSelectedRowChange: (rows) => setSelectedRows(rows)
              }
            }}
          />
        )}
      </div>
    </TabLayout>
  );
};

export default FoundItemsTab;
