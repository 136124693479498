import React, { FC, ReactNode } from 'react';
import styles from './tabLayout.module.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLocationData } from '../../../../contexts/LocationDataContext/LocationDataContext';
import { tabsConfiguration } from '../Sidebar/tabsConfiguration';
import NewFoundItemButton from '../Sidebar/NewFoundItemButton/NewFoundItemButton';
import { Button } from '../../../../sharedPacakge/components/Button/Button';
import ThreePOutlinedIcon from '@mui/icons-material/ThreePOutlined';
import { useIsMobile } from '../../../../utils/hooks/useIsMobile';
import Page from '../../../Page';
import { SetPageTitle } from '../../../../utils/SetPageTitle';
import { NewFoundCardButton } from '../Sidebar/NewFoundItemButton/NewFoundCardButton';
interface Props {
  children: ReactNode;
}

export const TabLayout: FC<Props> = ({ children }) => {
  const { location, userRole, organisation } = useLocationData();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const tabs = location
    ? tabsConfiguration(location.id).filter((tab) => {
        if (
          'visibleForRoles' in tab &&
          !tab.visibleForRoles!.includes(userRole)
        )
          return false;
        else return true;
      })
    : [];

  let parentTab: any;
  let activeTab: any;
  tabs.forEach((tab) => {
    if (tab.urlPattern === pathname) activeTab = tab;
    else if (tab.subTabs) {
      tab.subTabs.forEach((subTab) => {
        if (pathname.includes(tab.urlPattern)) {
          parentTab = tab;
          activeTab = subTab;
        }
      });
    }
  });

  SetPageTitle(activeTab ? activeTab.name : 'Findy');
  if (!location || !activeTab) return null;

  const onParentTabClick = (e: any) => {
    e.preventDefault();
    navigate(parentTab.route);
  };

  const onCreateInquiryClick = (e: any) => {
    e.preventDefault();
    if (!organisation) return;
    document.location.href = Page.external.lostSomething(
      organisation.id,
      organisation.name
    );
  };

  return (
    <div id="scrollable-tab" className={styles.container}>
      {isMobile && (
        <div className={styles.mobileSubHeader}>
          <NewFoundItemButton
            classes={{ button: styles.mobileSubHeader__buttons }}
          />
          <div className={styles.mobileSubHeader__secondRow}>
            <NewFoundCardButton
              className={styles.mobileSubHeader__buttons}
              fullWidth
            />
            <Button
              className={styles.mobileSubHeader__buttons}
              buttonType="secondary"
              onClick={onCreateInquiryClick}
              fullWidth
            >
              <ThreePOutlinedIcon />
              Efterlys
            </Button>
          </div>
        </div>
      )}
      <div>
        <div className={styles.breadcrumbs}>
          {location.name}{' '}
          {parentTab && (
            <div onClick={onParentTabClick} style={{ cursor: 'pointer' }}>
              /{parentTab.icon} {parentTab.name}{' '}
            </div>
          )}
          <div className={styles.breadcrumbs__active}>
            / <div className={styles.breadcrumbs__logo}>{activeTab?.icon}</div>{' '}
            {activeTab?.name}
          </div>
        </div>
        <h1 className={styles.title}>{activeTab?.description}</h1>
      </div>
      <div className={styles.content__container}>{children}</div>
    </div>
  );
};
