import React, { FC, useState } from 'react';
import { Button } from '../../../../../sharedPacakge/components/Button/Button';
import AddCardIcon from '@mui/icons-material/AddCard';
import NewFoundCardModal from './NewFoundCardModal';
interface Props {
  className?: string;
  secondary?: boolean;
  fullWidth?: boolean;
}

export const NewFoundCardButton: FC<Props> = ({
  className,
  secondary,
  fullWidth
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const onClick = async (e: any) => {
    e.preventDefault();
    setIsOpen(true);
  };

  return (
    <>
      <Button
        className={className}
        onClick={onClick}
        buttonType={secondary ? 'secondary' : 'primary'}
        fullWidth={fullWidth}
      >
        <AddCardIcon />
        Nyt Kort
      </Button>

      {isOpen && <NewFoundCardModal setIsOpen={setIsOpen} />}
    </>
  );
};
