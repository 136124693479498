import React, { useEffect, useState } from 'react';
import styles from './page.module.css';
import { Divider } from '@mui/material';
import Page from '../Page';
import Api from '../../servieces/Api/Api';
import { useAuth } from '../../contexts/AuthContext/AuthContext';
import CreateOrganisationForm from './CreateOrganisationForm/CreateOrganisationForm';
import CircleImage from '../../components/OrganisationLogo/OrganisationLogo';
import { useNavigate } from 'react-router-dom';
import { SetPageTitle } from '../../utils/SetPageTitle';
import logo from '../../assets/logo/darkFindyLogo.png';
import { OrganisationSelectButton } from '../LocationPage/components/Header/OrganisationSelectModal/OrganisationSelectButton';
import { superUserEmail } from '../../utils/constants';
const CreateOrganisationPage = () => {
  SetPageTitle('Opret Organisation');
  const { user } = useAuth();
  const navigate = useNavigate();
  const [organisations, setOrganisations] = useState<
    { id: number; name: string }[]
  >([]);
  const onOrganisationCreated = (locationId: number) =>
    navigate(Page.l.actions(locationId));

  useEffect(() => {
    if (!user) return;
    Api.get.allOrganisationWhereUserIsAdmin(user.id).then((_organisations) => {
      setOrganisations(_organisations);
    });
  }, [user]);

  if (!user) return null;

  const onOrganisationClick = (organisationId: number) => {
    Api.get
      .anyLocationIdFromOrganisation({
        userId: user.id,
        organisationId
      })
      .then((response) => {
        response && navigate(Page.l.actions(response.locationId));
      });
  };

  return (
    <div>
      <div className={styles.header}>
        <img
          className={styles.logo}
          src={logo}
          alt="findy-logo"
          width={100}
          height={70}
        />
        <div className={styles.userIcon}>
          <OrganisationSelectButton />
        </div>
      </div>
      <div className={styles.contentContainer}>
        <div>
          <div className={styles.textContainer}>
            <h3>Velkommen til Findy</h3>
            <h1>
              {user.email === superUserEmail
                ? 'Start med at lave en organisation'
                : 'Organisationer hvor du er medlem'}
            </h1>
          </div>
          <div className={styles.formContaienr}>
            <CreateOrganisationForm
              onOrganisationCreated={onOrganisationCreated}
            />
          </div>
          {organisations?.length > 0 ? (
            <div className={styles.organisationSection}>
              <p className={styles.organisationSection__title}>
                Gå til organisationen
              </p>
              {organisations.map((organisation, index) => (
                <div
                  key={index}
                  className={styles.organisation}
                  onClick={() => onOrganisationClick(organisation.id)}
                >
                  <CircleImage altText={organisation.name} />
                  {organisation.name}
                </div>
              ))}
            </div>
          ) : (
            <div>
              <div className={styles.awaitInvite__flexContainer}>
                <Divider className={styles.awaitInvite__divider} />
                <p className={styles.awaitInvite__text}>Eller</p>
                <Divider className={styles.awaitInvite__divider} />
              </div>
              <p className={styles.awaitInvite__text}>
                Vent på du bliver inviteret til en organisation
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateOrganisationPage;
