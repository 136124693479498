import { useSearchParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';

// Define the SearchParams type with the isArchived filter
interface SearchParams {
  query?: string;
  isArchived?: boolean;
  ids?: string; // We'll use a string to handle the comma-separated values from URL
}

export interface Filters {
  query: string;
  // page: number;
  // filters: string[];
  isArchived: boolean;
  ids: number[];
}

// Function to parse search parameters from URL
const parseSearchParams = (searchParams: URLSearchParams): Partial<Filters> => {
  const params: Partial<Filters> = {};

  if (searchParams.has('query')) {
    params.query = searchParams.get('query') as string;
  }
  // if (searchParams.has("page")) {
  //   params.page = parseInt(searchParams.get("page") as string, 10);
  // }
  // if (searchParams.has("filters")) {
  //   params.filters = searchParams.get("filters")?.split(",") || [];
  // }
  if (searchParams.has('isArchived')) {
    params.isArchived = searchParams.get('isArchived') === 'true';
  }

  // Parse the ids from the search parameters
  if (searchParams.has('ids')) {
    params.ids =
      searchParams
        .get('ids')
        ?.split(',')
        .map((id) => parseInt(id, 10)) || [];
  }

  return params;
};

// Function to serialize search parameters to URL
const serializeSearchParams = (params: Partial<Filters>): URLSearchParams => {
  const searchParams = new URLSearchParams();

  if (params.query) {
    searchParams.set('query', params.query);
  }
  // if (params.page !== undefined) {
  //   searchParams.set("page", params.page.toString());
  // }
  // if (params.filters) {
  //   searchParams.set("filters", params.filters.join(","));
  // }
  if (params.isArchived !== undefined) {
    searchParams.set('isArchived', params.isArchived.toString());
  }

  // Serialize the ids as a comma-separated string
  if (params.ids && params.ids.length > 0) {
    searchParams.set('ids', params.ids.join(','));
  }

  return searchParams;
};

// Custom hook to use and update search parameters
const useFilters = (): [
  Partial<Filters>,
  (params: Partial<Filters>) => void
] => {
  const [searchParams, setSearchParams] = useSearchParams();

  // Memoize the parsed parameters to avoid recalculating on each render
  const params = useMemo(() => parseSearchParams(searchParams), [searchParams]);

  // Local state to track when we need to update search params
  const [updatePending, setUpdatePending] = useState<Partial<Filters> | null>(
    null
  );

  // Update the URL search params only when necessary
  useEffect(() => {
    if (updatePending) {
      const currentSearchParams = serializeSearchParams(params);
      const newSearchParams = serializeSearchParams(updatePending);

      // Only update if the serialized params are different
      if (currentSearchParams.toString() !== newSearchParams.toString()) {
        setSearchParams(newSearchParams);
      }

      // Clear the updatePending flag to avoid repeated updates
      setUpdatePending(null);
    }
  }, [updatePending, params, setSearchParams]);

  // Function to request an update to search params
  const updateSearchParams = (newParams: Partial<Filters>) => {
    setUpdatePending({ ...params, ...newParams });
  };

  return [params, updateSearchParams];
};

export default useFilters;
