import React, { FC, ReactNode, useEffect, useState } from 'react';
import styles from './FoundItemsTable.module.css';

import GenericTable, {
  Column
} from '../../../../components/GenericTable/GenericTable';
import { formatDate } from '../../../../utils/formatting/formatDate';
import ViewFoundItemModal from '../ViewFoundItemModal/ViewFoundItemModal';
import {
  ArchivedFoundItem,
  FoundItem
} from '../../../../types/supabase/collections';
import { ItemStatus } from '../../../../sharedPacakge/types/ItemStatus';
import { LoadingSpinner } from '../../../../sharedPacakge/components/LoadingSpinner/LoadingSpinner';
import { translateItemStatus } from '../../../../utils/translate/translateItemStatus';
import { translateArchiveReason } from '../../../../utils/translate/translateArchiveReason';
import { FoundItemArchiveReason } from '../../../../sharedPacakge/types';

interface Props<T> {
  isLoading: boolean;
  foundItems: T[];
  updateFoundItem: (id: number, newValue: T) => void;
  callbacks?: {
    onArchiveCallback?: (id: number) => void;
    onUnArchiveCallback?: (id: number) => void;
    onUnmatchedCallback?: (id: number) => void;
  };
  options: {
    columns: Array<keyof T>;
    onRowClick?: (e: any, item: T) => void;
    renderActionsColumn?: (row: T) => ReactNode;
    renderNoFoundItems?: () => ReactNode;
    customColumns?: Column<T>[];
    multiSelect?: {
      enabled: true;
      onSelectedRowChange: (selectedRow: T[]) => void;
    };
  };
}

function FoundItemsTable<T extends FoundItem | ArchivedFoundItem>({
  isLoading,
  foundItems,
  updateFoundItem,
  options,
  callbacks
}: Props<T>) {
  const [viewingItem, setViewingItem] = useState<T | null>(null);
  const [viewingItemModalOpen, setViewingItemModalOpen] = useState(false);

  const onRowClick = (e: any, item: T) => {
    e.preventDefault();
    setViewingItem(item as T);
    setViewingItemModalOpen(true);
  };

  useEffect(() => {
    // When we update the found item locally, we need to update the value of the viewFoundItem
    setViewingItem((prevViewingItem) =>
      prevViewingItem
        ? (foundItems.find((item) => item.id === prevViewingItem.id) ?? null)
        : null
    );
  }, [foundItems]);

  if (isLoading)
    return (
      <div className={styles.loadingSpinner__container}>
        <LoadingSpinner />
      </div>
    );

  if (!isLoading && foundItems.length === 0)
    return options.renderNoFoundItems ? (
      <>{options.renderNoFoundItems()}</>
    ) : null;

  const allColumns: Column<T>[] = [
    {
      id: 'image',
      label: 'Billede',
      render: ({ image }) => (
        <img
          className={styles.itemImage}
          src={image}
          alt="genstand billede"
          width={90}
          height={90}
        />
      ),
      renderMobile: true
    },
    {
      id: 'category',
      label: 'Kategori',
      render: ({ category }) => category.name_danish,
      renderMobile: true
    },
    {
      id: 'description',
      label: 'Beskrivelse',
      render: ({ description }) => (
        <div className={styles.description}>{description}</div>
      ),
      renderMobile: ({ description }) => (
        <div
          className={styles.description}
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'block'
          }}
        >
          {description}
        </div>
      )
    },
    {
      id: 'found_at_date',
      label: 'Fundet tidspunkt',
      render: ({ found_at_date }) => formatDate(found_at_date)
    },
    {
      id: 'status',
      label: 'Status',
      render: ({ status }) => translateItemStatus(status),
      renderMobile: true
    },
    {
      id: 'id',
      label: 'ID',
      render: ({ id }) => id
    },
    {
      id: 'archive_reason',
      label: 'Arkiveringsårsag',
      render: (item) =>
        'archive_reason' in item
          ? translateArchiveReason(
              item.archive_reason as FoundItemArchiveReason
            )
          : ''
    }
  ];

  const columns = [
    ...allColumns.filter((column) =>
      options.columns.includes(column.id as keyof T)
    ),
    ...(options.customColumns ? options.customColumns : [])
  ];

  let props: any = {};
  if (options?.multiSelect) props.multiSelect = options?.multiSelect;
  if (options?.renderActionsColumn)
    props.actionsColumn = options?.renderActionsColumn;
  if (options?.onRowClick) props.onRowClick = options?.onRowClick;

  return (
    <div className={styles.container}>
      <GenericTable<T>
        columns={columns}
        data={foundItems}
        rowKey="id"
        onRowClick={onRowClick}
        {...props}
      />
      {viewingItemModalOpen && viewingItem && (
        <ViewFoundItemModal
          callbacks={callbacks}
          foundItem={viewingItem}
          updateFoundItem={updateFoundItem}
          setIsOpen={setViewingItemModalOpen}
        />
      )}
    </div>
  );
}

export default FoundItemsTable;
