import React, { FC } from 'react';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectProps
} from '@mui/material';
import { CategoryRecord } from '../../../types/supabase/collections';

interface CustomProps {
  label: string;
  value: CategoryRecord | null;
  onChange: (e: any, newValue: CategoryRecord | null) => void;
  disabled?: boolean;
  itemCategories: CategoryRecord[];
  labelClassName?: string;
}

type Props = CustomProps &
  Omit<
    SelectProps,
    | 'renderInput'
    | 'options'
    | 'getOptionLabel'
    | 'value'
    | 'onChange'
    | 'disabled'
  >;

const CategoryDropdown: FC<Props> = ({
  label,
  value,
  itemCategories,
  onChange,
  disabled = false,
  labelClassName = '',
  ...selectProps
}) => {
  return (
    <FormControl fullWidth disabled={disabled}>
      <InputLabel className={labelClassName}>Kategori</InputLabel>
      <Select
        value={value?.id || ''}
        onChange={(e) => {
          const selectedCategory = itemCategories.find(
            (category) => category.id === e.target.value
          );
          onChange(e, selectedCategory || null);
        }}
        label={label}
        disabled={disabled}
        {...selectProps}
      >
        {itemCategories.map((category) => (
          <MenuItem key={category.id} value={category.id}>
            {category.name_danish}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CategoryDropdown;
