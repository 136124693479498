import { ResponsiveGridContainer } from '../../../../../../components/ResponsiveGridContainer/ResponsiveGridContainer';
import { Modal } from '../../../../../../sharedPacakge/components/Modal/Modal';
import { Text } from '../../../../../../components/Text/Text';
import { FC, useEffect, useState } from 'react';
import { TextField } from '../../../../../../components/TextField/TextField';
import {
  UserOrInvite,
  UserTabUser
} from '../../../../../../servieces/Api/methods/get/getUsersAndInvites';
import { Gap } from '../../../../../../components/Gap/Gap';
import CircleImage from '../../../../../../components/OrganisationLogo/OrganisationLogo';
import styles from './editUserModal.module.css';
import { LocationPicker } from '../LocationPicker/LocationPicker';
import { RoleDropdown } from '../RoleDropdown/RoleDropdown';
import { Role } from '../../../../../../types/supabase/collections';
import { useLocationData } from '../../../../../../contexts/LocationDataContext/LocationDataContext';
import { Button } from '../../../../../../sharedPacakge/components/Button/Button';
import { arraysAreEqual } from '../../../../../../utils/arraysAreEqual';
import { supabase } from '../../../../../../servieces/supabase/supabaseClient';
import { assertNoSupabaseError } from '../../../../../../servieces/supabase/supabaseErrorHandling';
import Api from '../../../../../../servieces/Api/Api';
import logger from '../../../../../../sharedPacakge/logger';
import { useSnackbar } from '../../../../../../sharedPacakge/components/Snackbar/SnackbarContext';
import { SetState } from '../../../../../../sharedPacakge/types/utilityTypes';

type LocationsInfo = {
  id: number;
  name: string;
}[];

interface Props {
  onClose: () => void;
  user: UserTabUser;
  locationsUserHasAccessTo: LocationsInfo;
  allLocations: LocationsInfo;
  setUsersAndInvites: SetState<UserOrInvite[]>;
}
export const EditUserModal: FC<Props> = ({
  onClose,
  user,
  locationsUserHasAccessTo,
  allLocations,
  setUsersAndInvites
}) => {
  const { showSnackbar } = useSnackbar();
  const { organisation } = useLocationData();
  const { roleAccess } = useLocationData();

  const usersLocations = allLocations.filter((location) =>
    user.locationIds.some((id) => id === location.id)
  );
  const [selectedRole, setSelectedRole] = useState<Role>(user.role);
  const [selectedLocations, setSelectedLocations] =
    useState<LocationsInfo>(usersLocations);
  const [hasChanged, setHasChanged] = useState<boolean>(false);

  useEffect(() => {
    if (
      user.role === selectedRole &&
      arraysAreEqual(usersLocations, selectedLocations)
    ) {
      setHasChanged(false);
    } else {
      setHasChanged(true);
    }
  }, [selectedRole, selectedLocations, user]);

  useEffect(() => {
    if (selectedRole === Role.OrganisationAdmin) {
      setSelectedLocations(allLocations);
    } else {
      setSelectedLocations(
        usersLocations.length > 0
          ? usersLocations
          : ([locationsUserHasAccessTo[0]] as any)
      );
    }
  }, [selectedRole]);

  if (!organisation) return null;
  const onCancelClick = (e: any) => {
    e.preventDefault();
    setSelectedRole(user.role);
    setSelectedLocations(usersLocations);
  };

  const onSaveClick = async (e: any) => {
    e.preventDefault();
    try {
      if (selectedRole === Role.OrganisationAdmin) {
        await Api.delete.userRolesInOrg(user.id, organisation.id);

        const { error: insertError } = await supabase
          .from('user_roles')
          .insert({
            user_id: user.id,
            role: selectedRole,
            organisation_id: organisation.id
          });

        assertNoSupabaseError(
          {},
          insertError,
          'EditUserModalSaveClick insertError',
          { selectedRole }
        );
      } else {
        if (user.role === Role.OrganisationAdmin) {
          await Api.delete.userRolesInOrg(user.id, organisation.id);
        }
        const { error: deleteError } = await supabase
          .from('user_roles')
          .delete()
          .eq('user_id', user.id)
          .in(
            'location_id',
            locationsUserHasAccessTo.map((location) => location.id)
          );

        assertNoSupabaseError(
          {},
          deleteError,
          'EditUserModalSaveClick deleteError',
          { selectedRole, user_id: user.id, locationsUserHasAccessTo }
        );

        const userRolesToInsert = selectedLocations
          .filter((location) =>
            locationsUserHasAccessTo.some(({ id }) => id === location.id)
          )
          .map((location) => ({
            user_id: user.id,
            location_id: location.id,
            role: selectedRole,
            organisation_id: organisation.id
          }));
        const { error: insertError } = await supabase
          .from('user_roles')
          .insert(userRolesToInsert);

        assertNoSupabaseError(
          {},
          insertError,
          'EditUserModalSaveClick insertError',
          { selectedRole }
        );
      }

      showSnackbar('Ændringer gemt');
      setUsersAndInvites((prevState) =>
        prevState.map((userOrInvite) => {
          if (userOrInvite.id === user.id) {
            return {
              ...user,
              role: selectedRole,
              locationIds: selectedLocations.map((location) => location.id)
            };
          } else {
            return userOrInvite;
          }
        })
      );
    } catch (error) {
      logger.error(`EditUserModal OnSaveClick error ${JSON.stringify(error)}`);
      showSnackbar('der skete en fejl', 'error');
    }
  };

  return (
    <Modal title="Rediger bruger" onClose={onClose}>
      <div className={styles.image__container}>
        <CircleImage altText={user.fullName} size="large" />
      </div>

      <Gap size={24} />
      <Text type="body-strong">Oplysninger</Text>
      <Gap size={16} />

      <ResponsiveGridContainer>
        <TextField label="Navn" value={user.fullName} disabled />
        <TextField label="Email" value={user.email} disabled />

        <RoleDropdown
          value={selectedRole}
          onChange={(_, newValue) => setSelectedRole(newValue)}
          options={roleAccess?.manageUsers || []}
        />

        {selectedRole === Role.OrganisationAdmin ? (
          <TextField label="Adgang" value="Alle" disabled />
        ) : (
          <LocationPicker
            fixedOptions={selectedLocations.filter(
              (location) =>
                !locationsUserHasAccessTo.some(({ id }) => id === location.id)
            )}
            options={locationsUserHasAccessTo}
            value={selectedLocations}
            onChange={setSelectedLocations}
          />
        )}
      </ResponsiveGridContainer>
      {hasChanged && (
        <>
          <Gap size={24} />
          <div style={{ display: 'flex', gap: 16 }}>
            <Button buttonType="secondary" fullWidth onClick={onCancelClick}>
              Annuler
            </Button>
            <Button fullWidth onClick={onSaveClick}>
              Gem
            </Button>
          </div>
        </>
      )}
      <Gap size={24} />
    </Modal>
  );
};
