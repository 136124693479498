import React from 'react';
import {
  Navigate,
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext/AuthContext';
import AuthRoute from './contexts/AuthContext/AuthRoute';
import NoAuthOnlyhRoute from './contexts/AuthContext/NoAuthOnlyhRoute';
import Login from './pages/AuthPages/login/Login';
import Signup from './pages/AuthPages/signup/Signup';
import CreateOrganisationPage from './pages/CreateOrganisationPage/createOrganisationPage';
import LocationPage from './pages/LocationPage/LocationPage';
import { CustomizationTab } from './pages/LocationPage/tabs/CustomizationTab/CustomizationTab';
import { UsersTab } from './pages/LocationPage/tabs/UsersTab/UsersTab';
import FoundItemsTab from './pages/LocationPage/tabs/FoundItemsTab/FoundItemsTab';
import InquiriesTab from './pages/LocationPage/tabs/InquiriesTab/InquiriesTab';
import { ActionsTab } from './pages/LocationPage/tabs/ActionsTab/ActionsTab';
import { MatchmakingTab } from './pages/LocationPage/tabs/MatchmakingTab/MatchmakingTab';
import { EditInquiryFormTab } from './pages/LocationPage/tabs/EditInquiryForm/EditInquiryForm';
import { FeedbackTab } from './pages/LocationPage/tabs/FeedbackTab/FeedbackTab';
import { ConfirmResetPassword } from './pages/AuthPages/ConfirmResetPassword/ConfirmResetPassword';
import { ResetPassword } from './pages/AuthPages/ResetPassword/ResetPassword';
import { SnackbarProvider } from './sharedPacakge/components/Snackbar/SnackbarContext';
import { EmailTemplatesTab } from './pages/LocationPage/tabs/EmailTemplatesTab/EmailTemplatesTab';
import { InternalInsightsPage } from './pages/InternalInsightsPage/InternalInsightsPage';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Navigate to="/login" />
  },
  {
    path: '/login',
    element: <NoAuthOnlyhRoute element={<Login />} />
  },
  {
    path: '/signup',
    element: <NoAuthOnlyhRoute element={<Signup />} />
  },
  {
    path: '/reset-password',
    element: <NoAuthOnlyhRoute element={<ResetPassword />} />
  },
  {
    path: '/confirm-reset-password',
    element: <AuthRoute element={<ConfirmResetPassword />} />
  },
  {
    path: '/create-organisation',
    element: <AuthRoute element={<CreateOrganisationPage />} />
  },
  {
    path: '/l/:encodedLocationId',
    element: <AuthRoute element={<LocationPage />} />,
    children: [
      {
        path: 'actions',
        element: <ActionsTab />
      },
      {
        path: 'actions/matchmaking/:encodedFoundItemId',
        element: <MatchmakingTab />
      },
      {
        path: 'found-items',
        element: <FoundItemsTab />
      },
      {
        path: 'inquiries',
        element: <InquiriesTab />
      },
      {
        path: 'edit-inquiry-form',
        element: <EditInquiryFormTab />
      },
      {
        path: 'customization',
        element: <CustomizationTab />
      },
      {
        path: 'email-templates',
        element: <EmailTemplatesTab />
      },
      {
        path: 'users',
        element: <UsersTab />
      },
      {
        path: 'feedback',
        element: <FeedbackTab />
      }
    ]
  },
  {
    path: '/internal-insights',
    element: <AuthRoute element={<InternalInsightsPage />} />
  }
]);

const App: React.FC = () => {
  return (
    <SnackbarProvider>
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </SnackbarProvider>
  );
};

export default App;
