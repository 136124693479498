import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import styles from './viewFoundItemModal.module.css';
import { formatDate } from '../../../../utils/formatting/formatDate';
import { useLocationData } from '../../../../contexts/LocationDataContext/LocationDataContext';
import Api from '../../../../servieces/Api/Api';
import {
  ArchivedFoundItem,
  FoundItem,
  userProfileRecord
} from '../../../../types/supabase/collections';
import { getDateDaysFromNow } from '../../../../utils/dateInXDays';
import { daysUntilDateText } from '../../../../utils/daysUntilDate';
import { unMatchItems } from '../../../../servieces/matchmaking/unMatchItems';
import { EditableFoundItemTextField } from './EditableFoundItemTextField/EditableFoundItemTextField';
import { Modal } from '../../../../sharedPacakge/components/Modal/Modal';
import { translateItemStatus } from '../../../../utils/translate/translateItemStatus';
import { FoundItemArchiveReason } from '../../../../sharedPacakge/types';
import { Button } from '../../../../sharedPacakge/components/Button/Button';
import { SetState } from '../../../../sharedPacakge/types/utilityTypes';
import { useSnackbar } from '../../../../sharedPacakge/components/Snackbar/SnackbarContext';
import { ItemStatus } from '../../../../sharedPacakge/types/ItemStatus';
import logger from '../../../../sharedPacakge/logger';
import { LoadingSpinner } from '../../../../sharedPacakge/components/LoadingSpinner/LoadingSpinner';

interface Props<T> {
  foundItem: T;
  updateFoundItem: (id: number, newValue: T) => void;
  setIsOpen: SetState<boolean>;
  callbacks?: {
    onArchiveCallback?: (id: number) => void;
    onUnArchiveCallback?: (id: number) => void;
    onUnmatchedCallback?: (id: number) => void;
  };
}

function ViewFoundItem<T extends FoundItem | ArchivedFoundItem>({
  foundItem,
  updateFoundItem,
  setIsOpen,
  callbacks
}: Props<T>) {
  const isArchivedItem = 'archive_reason' in foundItem;
  const { location } = useLocationData();
  const { showSnackbar } = useSnackbar();
  const [resolveReason, setResolveReason] = useState<
    FoundItemArchiveReason | ''
  >(isArchivedItem ? (foundItem.archive_reason as FoundItemArchiveReason) : '');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [matchId, setMatchId] = useState<number | null>(null);
  const [hasMatch, setHasmatch] = useState<boolean>(false);
  const [postedByUser, setPostedByUser] = useState<userProfileRecord | null>(
    null
  );

  useEffect(() => {
    if (foundItem.posted_by_user_id) {
      Api.get
        .userProfile(foundItem.posted_by_user_id)
        .then(
          (userProfileResponse) =>
            userProfileResponse && setPostedByUser(userProfileResponse)
        );
    }

    if (
      foundItem.status === ItemStatus.ReadyForPickup ||
      foundItem.status === ItemStatus.LeftBehind
    ) {
      // setIsLoading(true);
      if (isArchivedItem) {
        Api.get
          .archivedMatches({
            customQuery: (query) =>
              query
                .eq('found_item_id', foundItem.id)
                .eq('match_confirmed', true)
          })
          .then((matches) => {
            if (matches.length !== 1) {
              logger.error(
                `ViewFoundItemModal !matches || matches.length !== 1 -> ${JSON.stringify(matches)}`
              );
              return;
            }
            setMatchId(matches[0].inquiry_id);
            setHasmatch(true);
            setIsLoading(false);
          });
      } else {
        Api.get
          .matches({
            customQuery: (query) =>
              query
                .eq('found_item_id', foundItem.id)
                .eq('match_confirmed', true)
          })
          .then((matches) => {
            if (matches.length !== 1) {
              logger.error(
                `ViewFoundItemModal !matches || matches.length !== 1 -> ${JSON.stringify(matches)}`
              );
              return;
            }
            setMatchId(matches[0].inquiry_id);
            setHasmatch(true);
            setIsLoading(false);
          });
      }
    }
  }, []);

  if (!location) return null;

  const onResolveClick = (e: any) => {
    e.preventDefault();
    if (!resolveReason) {
      showSnackbar('Mangler årsag', 'error');
      return;
    }

    setIsLoading(true);
    Api.put
      .archiveItems({
        ids: [foundItem.id],
        type: 'foundItems',
        archiveReason: resolveReason
      })
      .then(() => {
        callbacks?.onArchiveCallback &&
          callbacks.onArchiveCallback(foundItem.id);
        setIsLoading(false);
        showSnackbar('Afviklet');
        setIsOpen(false);
      })
      .catch(() => showSnackbar('Der skete en fejl', 'error'));
  };

  const onReopenClick = (e: any) => {
    e.preventDefault();
    if (!isArchivedItem) return;

    setIsLoading(true);
    Api.put
      .unArchiveItems({
        ids: [foundItem.id],
        type: 'foundItems',
        newExpiryDate: getDateDaysFromNow(10)
      })
      .then(() => {
        callbacks?.onArchiveCallback &&
          callbacks.onArchiveCallback(foundItem.id);
        setIsLoading(false);
        showSnackbar('genåbnet');
        setIsOpen(false);
      })
      .catch(() => showSnackbar('Der skete en fejl', 'error'));
  };

  const onUnMatchClick = (e: any) => {
    e.preventDefault();
    if (isArchivedItem || !matchId) return;

    setIsLoading(true);
    unMatchItems({
      inquiryId: matchId,
      foundItemId: foundItem.id,
      location
    })
      .then(() => {
        showSnackbar('Genstand er Unmatched!');
        callbacks?.onUnmatchedCallback &&
          callbacks.onUnmatchedCallback(foundItem.id);
        setIsOpen(false);
      })
      .catch(() => showSnackbar('Der skete en fejl', 'error'))
      .finally(() => setIsLoading(false));
  };

  const onChnageStatus = async (newStatus: ItemStatus) => {
    await Api.put
      .foundItem(foundItem.id, {
        status: newStatus
      })
      .then(() => showSnackbar('Status ændret'));
  };

  const onManuelMatchesClick = (e: any) => {
    e.preventDefault();

    // check om gesntanden eksiterer og er i samme lokation
    // Sæt match op
  };

  return (
    <Modal
      className={styles.modal}
      title={`Fundet hos ${location.name}`}
      setIsOpen={setIsOpen}
    >
      <div className={styles.sectionContainer}>
        <p className={styles.sectionTitle}>Billede</p>
        {foundItem?.image ? (
          <img
            className={styles.image}
            src={foundItem.image}
            alt="bilede"
            width={300}
            height={300}
          />
        ) : (
          <p>(har ikke vedhæftet billede)</p>
        )}
      </div>

      <div className={styles.sectionContainer}>
        <p className={styles.sectionTitle}>
          Genstand
          {isArchivedItem ? <small> (Arkiveret)</small> : ''}
        </p>
        <p className={styles.idText}>
          <strong style={{ fontWeight: 'bold' }}>Id:</strong> {foundItem.id}
        </p>
        <div className={styles.section}>
          <p className={styles.text}>
            <strong style={{ fontWeight: 'bold' }}>Kategori:</strong>{' '}
            {foundItem.category.name_danish}
          </p>
          <span className={styles.text}>
            <strong style={{ fontWeight: 'bold' }}>Beskrivelse:</strong>{' '}
            <EditableFoundItemTextField
              foundItem={foundItem}
              updateFoundItem={updateFoundItem}
              field="description"
            />
          </span>
          <p className={styles.text}>
            <strong style={{ fontWeight: 'bold' }}>Fundet Omkring:</strong>{' '}
            {formatDate(foundItem.found_at_date)}
          </p>
        </div>
      </div>
      {!isArchivedItem && (
        <div className={styles.sectionContainer}>
          <div className={styles.section}>
            <p className={styles.text}>
              <strong style={{ fontWeight: 'bold' }}>Status:</strong>{' '}
              {translateItemStatus(foundItem.status)}
            </p>
            <p className={styles.text}>
              <strong style={{ fontWeight: 'bold' }}>Udløber om:</strong>{' '}
              {daysUntilDateText(foundItem.expiry_date)}
            </p>
            <p className={styles.text}>
              <strong style={{ fontWeight: 'bold' }}>Match:</strong>{' '}
              {matchId ? `efterlysnings-id: ${matchId}` : 'ingen match'}
            </p>
          </div>
        </div>
      )}

      <div className={styles.sectionContainer}>
        <div className={styles.section}>
          <p className={styles.text}>
            <strong style={{ fontWeight: 'bold' }}>Fundet ved:</strong>{' '}
            {foundItem.found_at_location === ''
              ? 'ikke specificeret'
              : foundItem.found_at_location}
          </p>
          {isArchivedItem && (
            <p className={styles.text}>
              <strong style={{ fontWeight: 'bold' }}>Match:</strong>{' '}
              {matchId ? `efterlysnings-id: ${matchId}` : 'ingen match'}
            </p>
          )}
        </div>
      </div>

      <div className={styles.sectionContainer}>
        <p className={styles.sectionTitle}>Håndtering</p>
        <div className={styles.section}>
          <p className={styles.text}>
            <strong style={{ fontWeight: 'bold' }}>Oprettet af:</strong>{' '}
            {postedByUser
              ? postedByUser.full_name
              : 'bruger eksisterer ikke længere'}
          </p>
          <span className={styles.text}>
            <strong style={{ fontWeight: 'bold' }}>Opbevaringssted:</strong>{' '}
            <span>
              <EditableFoundItemTextField
                foundItem={foundItem}
                updateFoundItem={updateFoundItem}
                field="storage_location"
              />
            </span>
          </span>
          <span className={styles.text}>
            <strong style={{ fontWeight: 'bold' }}>Noter:</strong>{' '}
            <span>
              <EditableFoundItemTextField
                foundItem={foundItem}
                updateFoundItem={updateFoundItem}
                field="notes"
              />
            </span>
          </span>
        </div>
      </div>

      {!isArchivedItem && !isLoading && (
        <>
          <p className={styles.sectionTitle}>Afvikl</p>
          <div className={styles.sectionContainer}>
            <div className={styles.section}>
              <FormControl fullWidth disabled={isLoading}>
                <InputLabel id="demo-simple-select-label">Årsag</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={resolveReason}
                  label="Årsag"
                  onChange={(e: any) => {
                    setResolveReason(e.target.value as FoundItemArchiveReason);
                  }}
                >
                  <MenuItem
                    value={
                      '(manually archived) Physical inquiry & immediate pickup'
                    }
                  >
                    Ejer henvendte sig fysisk
                  </MenuItem>
                  <MenuItem value={'(manually archived) Donated'}>
                    Doneret
                  </MenuItem>
                  <MenuItem value={'(manually archived) Handed to police'}>
                    Afleveret til Politiet
                  </MenuItem>
                  <MenuItem value={'(manually archived) Kept'}>
                    Beholdt selv
                  </MenuItem>
                </Select>
              </FormControl>
              <Button onClick={onResolveClick} disabled={isLoading}>
                Færdig
              </Button>
            </div>
          </div>
        </>
      )}
      {!isArchivedItem &&
        !isLoading &&
        foundItem.status === ItemStatus.AwaitingMatch && (
          <>
            <div className={styles.sectionContainer}>
              <Button
                onClick={() => onChnageStatus(ItemStatus.ReadyForPickup)}
                disabled={isLoading}
              >
                Sæt til afhentning
              </Button>
              <small>
                Sæt manuelt genstand til afhentning, uden at have matchende
                efterlysning. Eksempelvis for brugere der ringer ind.
              </small>
            </div>
          </>
        )}
      {!isArchivedItem &&
        !isLoading &&
        foundItem.status === ItemStatus.ReadyForPickup &&
        !matchId && (
          <>
            <div className={styles.sectionContainer}>
              <Button
                onClick={() => onChnageStatus(ItemStatus.AwaitingMatch)}
                disabled={isLoading}
              >
                Fjern fra afhentning
              </Button>
            </div>
          </>
        )}

      {!isArchivedItem && hasMatch && !isLoading && (
        <>
          <p className={styles.idText}>
            Var genstanden matched med den forkerte efterlysning?
          </p>
          <Button
            onClick={onUnMatchClick}
            disabled={isLoading}
            buttonType="secondary"
            style={{ margin: 'auto' }}
          >
            Unmatch
          </Button>
        </>
      )}

      {isArchivedItem && !isLoading && (
        <>
          <p className={styles.idText}>
            <strong style={{ fontWeight: 'bold' }}>Afviklet:</strong>{' '}
            {formatDate(foundItem.archived_date)}
          </p>
          <p className={styles.idText}>Vil du åbne sagen op igen i 10 dage?</p>
          <Button
            onClick={onReopenClick}
            buttonType="secondary"
            style={{ margin: 'auto' }}
          >
            Genaktivér
          </Button>
        </>
      )}
      {isLoading && <LoadingSpinner />}
    </Modal>
  );
}

export default ViewFoundItem;
