import FindyApi from '../../../../../sharedPacakge/findyApi/fetchFindyApi';
import { useSnackbar } from '../../../../../sharedPacakge/components/Snackbar/SnackbarContext';
import { useAuth } from '../../../../../contexts/AuthContext/AuthContext';
import { useLocationData } from '../../../../../contexts/LocationDataContext/LocationDataContext';
import { useEventDispatcher } from '../../../../../contexts/EventsProvider.ts/EventsProvider';
import { Database } from '../../../../../types/supabase/supabaseDB';

type FoundItemInsert = Database['public']['Tables']['found_items']['Insert'];
type GenericPostFoundItem = Omit<
  FoundItemInsert,
  'image' | 'expiry_date' | 'organisation_id'
>;
interface PostFoundItemWithoutImageDetection extends GenericPostFoundItem {
  imageDetection: false;
}

interface PostFoundItemWithImageDetection
  extends Omit<
    GenericPostFoundItem,
    'category_id' | 'description' | 'is_high_value_item'
  > {
  imageDetection: true;
}

export type PostFoundItemPayloadData =
  | PostFoundItemWithoutImageDetection
  | PostFoundItemWithImageDetection;

export const usePostFoundItem = () => {
  const { showSnackbar } = useSnackbar();
  const { user } = useAuth();
  const { location } = useLocationData();
  const triggerEvent = useEventDispatcher();

  const postFoundItem = (
    imageFile: File,
    data: Omit<PostFoundItemPayloadData, 'posted_by_user_id' | 'location_id'>
  ) => {
    if (!location || !user) return;

    const formData = new FormData();
    formData.append('image', imageFile);

    const postFoundItemPayload: PostFoundItemPayloadData = {
      ...data,
      posted_by_user_id: user.id,
      location_id: location.id
    } as PostFoundItemPayloadData;

    formData.append('data', JSON.stringify(postFoundItemPayload));

    triggerEvent('foundItemUploaded', async () => {
      try {
        const foundItem = await FindyApi.post('found-item', formData);
        showSnackbar('Genstand tilføjet');
        return foundItem;
      } catch (e) {
        showSnackbar('Der skete en fejl. genstand blev ikke oprettet', 'error');
      }
    });
  };

  return { postFoundItem };
};
